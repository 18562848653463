// src/App.js

import React, { useState } from 'react';
import './App.css';

const UnlockContainer = () => {
  const [passphrase, setPassphrase] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setPassphrase(e.target.value);
    setError(''); // Clear error when user types
  };

  const handleUnlock = () => {
    // Example passphrase for validation
    const correctPassphrase = 'KingKong1122'; // Replace with your actual passphrase

    if (passphrase === correctPassphrase) {
      // Handle successful unlock action here
      alert('Unlocking...'); // You can replace this with navigation or other logic
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="container">
      <div className="lock-icon">🔒</div>
      <h2 className="title">Container is Locked</h2>
      <p className="subtitle">Enter container decryption passphrase</p>
      <input
        type="password"
        placeholder="Passphrase"
        value={passphrase}
        onChange={handleInputChange}
        className="input"
      />
      <button onClick={handleUnlock} className="button">
        UNLOCK
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default UnlockContainer;
